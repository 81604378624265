import { ReactComponent as DraftIcon } from "../assets/images/draftIcon.svg";
import { ReactComponent as ToBeDispatchedIcon } from "../assets/images/to-be-dispatched-icon.svg";
import { ReactComponent as DispatchedIcon } from "../assets/images/dispatched-icon.svg";
import { ReactComponent as DeliveredIcon } from "../assets/images/deliveredIcon.svg";
import { ReactComponent as CancelledIcon } from "../assets/images/cancelled-icon.svg";

export const loginTokenKey = "user-access-token";

interface ITrackingItemsMapping {
  [key: string]: {
    title: string;
    labelForKey?: { [key: string]: string };
  };
}

export const serviceChargeType: { [key: string]: string } = {
  PERWEIGHTBYMEASURE: "Per Weight By Measure",
  FIXED: "Fixed",
};

export const routeChargeType: { [key: string]: string } = {
  ORIGIN: "ORIGIN",
  ONCARRIAGE: "ON-CARRIAGE",
  PRECARRIAGE: "PRE-CARRIAGE",
  FREIGHT: "FREIGHT",
  DESTINATION: "DESTINATION",
};

export const allIncoTerms = [
  {
    value: "FOB",
    label: "FOB",
    isDoor: false,
    freightTerm: "COLLECT",
  },
  {
    value: "FCA",
    label: "FCA",
    isDoor: false,
    freightTerm: "COLLECT",
  },
  {
    value: "EXWORKS",
    label: "EXWORKS",
    isDoor: true,
    freightTerm: "COLLECT",
  },
  {
    value: "CIF",
    label: "CIF",
    isDoor: false,
    freightTerm: "PREPAID",
  },
  {
    value: "DDU",
    label: "DDU",
    isDoor: true,
    freightTerm: "PREPAID",
  },
];

export const packageType: { [key: string]: string } = {
  PALLETS: "Pallets",
  BOXES: "Boxes",
  DRUMS: "Drums",
  CARTONS: "Cartons",
};

export const shipmentType: { [key: string]: string } = {
  STACKABLE: "Stackable",
  NON_STACKABLE: "Non Stackable",
};

export const cargoType: { [key: string]: string } = {
  PERSONAL_EFFECTS: "Personal Effects",
  COMMERCIAL: "Commercial",
};

export const bookingStatus: {
  [key: string]: {
    color: string;
    background: string;
    title: string;
    statusTitle: string;
    doorStatusTitle: string;
    icon?: any;
  };
} = {
  DRAFT: {
    color: "#5B5858",
    background: "#E3E3E3",
    title: "Draft",
    statusTitle: "Order in Draft",
    doorStatusTitle: "Order in Draft",
    icon: DraftIcon,
  },
  CONFIRMED: {
    color: "#8F4700",
    background: "#F5D9A8",
    title: "To be Dispatched",
    statusTitle: "Order Confirmed",
    doorStatusTitle: "Order Confirmed",
    icon: ToBeDispatchedIcon,
  },
  DISPATCHED: {
    color: "#0044FF",
    background: "#E9EEFD",
    title: "Dispatched",
    statusTitle: "Dispatched",
    doorStatusTitle: "Dispatched",
    icon: DispatchedIcon,
  },
  DELIVERED: {
    color: "#24663B",
    background: "#C3E6CD",
    title: "Delivered",
    statusTitle: "Delivery",
    doorStatusTitle: "Delivered",
    icon: DeliveredIcon,
  },
  CANCELLED: {
    color: "#C13333",
    background: "#FFCFCF",
    title: "Cancelled",
    statusTitle: "Cancelled",
    doorStatusTitle: "Cancelled",
    icon: CancelledIcon,
  },
  // DOOR_ORIGIN_CREATED: {
  //   color: "#C13333",
  //   background: "#FFCFCF",
  //   title: "Door Pickup Scheduled",
  //   statusTitle: "Pickup Scheduled",
  //   doorStatusTitle: "Pickup Scheduled",
  //   icon: CancelledIcon,
  // },
  // DOOR_ORIGIN_PICKUP: {
  //   color: "#C13333",
  //   background: "#FFCFCF",
  //   title: "Order in Transit",
  //   statusTitle: "Order in Transit",
  //   doorStatusTitle: "Order in Transit",
  //   icon: CancelledIcon,
  // },
  // DOOR_ORIGIN_DELIVERY: {
  //   color: "#C13333",
  //   background: "#FFCFCF",
  //   title: "Cancelled",
  //   statusTitle: "Cancelled",
  //   doorStatusTitle: "Cancelled",
  //   icon: CancelledIcon,
  // },
  // DOOR_DESTINATION_CREATED: {
  //   color: "#C13333",
  //   background: "#FFCFCF",
  //   title: "Cancelled",
  //   statusTitle: "Cancelled",
  //   doorStatusTitle: "Cancelled",
  //   icon: CancelledIcon,
  // },
  // DOOR_DESTINATION_PICKUP: {
  //   color: "#C13333",
  //   background: "#FFCFCF",
  //   title: "Cancelled",
  //   statusTitle: "Cancelled",
  //   doorStatusTitle: "Cancelled",
  //   icon: CancelledIcon,
  // },
  // DOOR_DESTINATION_DELIVERY: {
  //   color: "#C13333",
  //   background: "#FFCFCF",
  //   title: "Cancelled",
  //   statusTitle: "Cancelled",
  //   doorStatusTitle: "Cancelled",
  //   icon: CancelledIcon,
  // },
};

export const importTrackingStatus: ITrackingItemsMapping = {
  ORDER_PLACED: {
    title: "Your Order has been placed",
  },
  DISPATCHED: {
    title: "Your order has been dispatched",
    labelForKey: {
      mblNo: "MBL Number",
      mblDate: "MBL Date",
      movement: "Movement",
      portOfLoading: "Port Of Loading",
      portOfDischarge: "Port Of Discharge",
      vessel: "Vessel",
      voyage: "Voyage",
      etaPodDate: "ETA POD Date",
      igmNo: "IGM Number",
      igmDate: "IGM Date",
    },
  },
  MBL_GENERATED: {
    title: "MBL has been generated",
    labelForKey: {
      mblNo: "MBL Number",
      mblDate: "MBL Date",
      movement: "Movement",
      portOfLoading: "Port Of Loading",
      portOfDischarge: "Port Of Discharge",
      vessel: "Vessel",
      voyage: "Voyage",
      etaPodDate: "ETA POD Date",
      igmNo: "IGM Number",
      igmDate: "IGM Date",
    },
  },
  HBL_GENERATED: {
    title: "HBL has been generated",
    labelForKey: {
      blNo: "HBL Number",
      blDate: "BL Date",
      correspondingBlNo: "Corresponding BL Number",
      origin: "Origin",
      destination: "Destination",
      packages: "Packages",
      measurement: "Measurement",
      grossWt: "Gross Weight (kg)",
      smtpNo: "SMTP Number",
      subLineNo: "Subline Number",
    },
  },
  ARRIVAL: {
    title: "Arrival",
    labelForKey: {
      arrivalDate: "Arrival Date",
    },
  },
  CONTAINER_DESTUFFING: {
    title: "Container Destuffing",
    labelForKey: {
      cntrNo: "Container Number",
      size: "Size",
      type: "Type",
      railingDate: "Railing Date",
      arrivalDate: "Arrival Date",
      arrivalStation: "Arrival Station",
      destuffingDate: "Destuffing Date",
    },
  },
  DELIVERED: {
    title: "Your order has been delivered at port",
  },
  DOOR_DESTINATION_CREATED: {
    title: "Door Delivery Scheduled",
  },
  DOOR_DESTINATION_PICKUP: {
    title: "Door Delivery in Transit",
  },
  DOOR_DESTINATION_DELIVERY: {
    title: "Delievered at Door",
  },
  CANCELLED: {
    title: "You order has been cancelled",
  },
};

export const exportTrackingStatus: ITrackingItemsMapping = {
  ORDER_PLACED: {
    title: "Your Order has been placed",
  },
  CONFIRMED: {
    title: "Your Order has been confirmed",
    labelForKey: {
      sBillNo: "S Bill Number",
      packages: "Packages",
      grossWt: "Gross Weight (kg)",
      measurement: "Measurement",
      shipper: "Shipper",
      cha: "CHA",
    },
  },
  DOOR_ORIGIN_CREATED: {
    title: "Door Pickup Scheduled",
  },
  DOOR_ORIGIN_PICKUP: {
    title: "Door Pickup in Transit",
  },
  DOOR_ORIGIN_DELIVERY: {
    title: "Delievered at Port",
  },
  CARTED: {
    title: "Consignment has been carted",
    labelForKey: {
      cartingStation: "Carting Station",
    },
  },
  CUSTOM_CLEAREANCE: {
    title: "Consignment has been cleared by Customs",
    labelForKey: {
      readyDate: "Ready Date",
    },
  },
  CONTAINER_ALLOTTED: {
    title: "Container has been allotted",
    labelForKey: {
      cntrNo: "Container Number",
      stuffingDate: "Stuffing Date",
      stuffingStation: "Stuffing Station",
      portOfLoading: "Port of Loading",
    },
  },
  DISPATCHED: {
    title: "Your order has been dispatched",
    labelForKey: {
      vessel: "Vessel",
      etapod: "ETA POD",
    },
  },
  HBL_GENERATED: {
    title: "HBL has been generated",
    labelForKey: {
      blNo: "HBL Number",
      releasedOn: "Released On",
      placeOfIssue: "Place of Issue",
      destination: "Destination",
      transhipmentDate: "Transhipment Date",
      transhipmentPort: "Transhipment Port",
      etaDestinationDate: "ETA Destination Date",
      doIssuedDate: "DO Issued Date",
    },
  },
  MBL_GENERATED: {
    title: "MBL has been generated",
    labelForKey: {
      mblNo: "MBL Number",
      movement: "Movement",
      lineName: "Line Name",
    },
  },
  TRANSIT_REACHED: {
    title: "Transit Reached",
    labelForKey: {
      transhipmentDate: "Transhipment Date",
      transhipmentPort: "Transhipment Port",
      etaDestinationDate: "ETA Destination Date",
    },
  },
  DELIVERED: {
    title: "Your order has been delivered",
  },
  CANCELLED: {
    title: "You order has been cancelled",
  },
};

export const bLType: { [key: string]: string } = {
  EXPRESS: "Express",
  OBL: "Original Bill of Lading (OBL)",
  RFS: "Received For Shipment (RFS)",
  // TELEX: "Telegraphic Transfer (TELEX)",
};

export const bookingBlType: { [key: string]: string } = {
  DIRECT_CARRIER_BL: "Direct Carrier BL",
  EMU_HOUSE_BL: "EMU House BL",
};

export const routingType: { [key: string]: string } = {
  IMPORT: "Import",
  EXPORT: "Export",
};

export const userNationality: { [key: string]: string } = {
  INDIAN: "Indian",
  NON_INDIAN: "Overseas",
};

export const customerType: { [key: string]: string } = {
  SHIPPER: "Shipper",
  CONSIGNEE: "Consignee",
  CUSTOMS_BROKER: "Customs Broker",
  FORWARDER: "Forwarder",
};

export const customerStatus: { [key: string]: string } = {
  CONFIRMED: "Confirmed",
  REQUESTED: "Requested",
  REJECTED: "Rejected",
};

export const overRideType: { [key: string]: string } = {
  INCLUSION: "INCLUSION",
  EXCLUSION: "EXCLUSION",
  OVERRIDE: "OVERRIDE",
};

export const bookingType: { [key: string]: string } = {
  LCL: "LCL",
  FCL: "FCL",
};

export const containerTypeAndSize: { [key: string]: string } = {
  TWENTY_FEET_GP: "20'GP",
  FOURTY_FEET_GP: "40'GP",
  FOURTY_FEET_HC: "40'HC",
};

export const stuffingType: { [key: string]: string } = {
  FACTORY_STUFFING: "Factory Stuffing",
  DOCK_STUFFING: "Dock Stuffing",
};
