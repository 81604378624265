import { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row } from "antd";
import ReactECharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";

import { getFormattedAmount } from "../../../utils/helper";

import CustomerHomeBookingTable from "./customer-home-booking-table";
import styles from "./home.module.scss";

export default function CustomerHomeCharts() {
  const [loadingData, setLoadingData] = useState(true);
  const [statusData, setStatusData] = useState<number[]>([]);
  const [expenseData, setExpenseData] = useState({
    import: 0,
    export: 0,
    currency: "INR",
  });
  const navigate = useNavigate();

  const bookingStatus = [
    { key: "DRAFT", title: "Draft", color: "#fac858" },
    { key: "CONFIRMED", title: "To be Dispatched", color: "#5470c6" },
    { key: "DISPATCHED", title: "Dispatched", color: "#91cc75" },
    { key: "DELIVERED", title: "Delivered", color: "#3ba272" },
    { key: "CANCELLED", title: "Cancelled", color: "#ee6666" },
  ];

  useEffect(() => {
    setLoadingData(true);
    axios
      .get("customer/dashboard/all")
      .then((resp) => {
        setStatusData(
          bookingStatus.map(
            (el) =>
              resp.data?.data?.statusCounts?.find(
                (inner: any) => inner.status === el.key
              )?._count || 0
          )
        );
        const tempExpenseObj = {
          import: 0,
          export: 0,
          currency: resp.data?.data?.currency || "INR",
        };
        resp.data?.data?.expences?.forEach((inner: any) => {
          if (inner.routingType === "EXPORT") {
            tempExpenseObj.export = inner._sum?.totalPrice || 0;
          } else if (inner.routingType === "IMPORT") {
            tempExpenseObj.import = inner._sum?.totalPrice || 0;
          }
        });
        setExpenseData(tempExpenseObj);
      })
      .finally(() => setLoadingData(false));
  }, []);

  const openBookings = (name: string) => {
    const status = bookingStatus.find((el) => el.title === name)?.key;
    navigate(`/manage/bookings`, { state: { bookingStatus: status } });
  };

  return (
    <Row
      gutter={[24, 24]}
      style={{ marginBottom: 24 }}
      className={styles["charts-wrapper"]}
    >
      <Col xs={24}>
        <Card className={styles["block-card"]} title="AMOUNT SPENT">
          <div className={styles["block-card-item"]}>
            <div className={styles["block-card-title"]}>
              {getFormattedAmount(expenseData.import, expenseData.currency)}
            </div>
            <div className={styles["block-card-sub-title"]}>Import</div>
          </div>

          <div className={styles["block-card-item"]}>
            <div className={styles["block-card-title"]}>
              {getFormattedAmount(expenseData.export, expenseData.currency)}
            </div>
            <div className={styles["block-card-sub-title"]}>Export</div>
          </div>

          <div className={styles["block-card-item"]}>
            <div className={styles["block-card-title"]}>
              {getFormattedAmount(
                expenseData.import + expenseData.export,
                expenseData.currency
              )}
              <div className={styles["block-card-sub-title"]}>Total</div>
            </div>
          </div>
        </Card>
      </Col>

      <Col xs={24} md={10}>
        <Card title="BOOKINGS BY STATUS" className={styles["chart-card"]}>
          <ReactECharts
            style={{ height: 225 }}
            showLoading={loadingData}
            option={{
              grid: { top: 16, right: 20, bottom: 24, left: 48 },
              xAxis: {
                axisTick: { alignWithLabel: true },
                axisLabel: { interval: 0, overflow: "truncate", width: 50 },
                type: "category",
                data: bookingStatus.map((el) => el.title),
              },
              yAxis: {
                name: "No. of Bookings",
                nameLocation: "center",
                nameGap: 35,
              },
              series: [
                {
                  data: statusData,
                  type: "bar",
                  smooth: true,
                  colorBy: "data",
                },
              ],
              color: bookingStatus.map((el) => el.color),
              tooltip: {
                trigger: "axis",
                formatter: ([{ marker, name, value }]: any) =>
                  `${marker} ${name} <b style="margin-left: 10px;">${value}</b>`,
              },
            }}
            lazyUpdate={true}
            onEvents={{ click: ({ name }: any) => openBookings(name) }}
          />
        </Card>
      </Col>

      <CustomerHomeBookingTable />
    </Row>
  );
}
